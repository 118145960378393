// project imports
import { Outlet } from 'react-router-dom';
import Loadable from 'ui-component/Loadable';
// import Header from './Header';
// import Footer from './Footer';
import { lazy } from 'react';

const Header = Loadable(lazy(() => import('./Header')));
const Footer = Loadable(lazy(() => import('./Footer')));

const HomepageLayout = () => (
    <>
        <Outlet />
        <Header />
        <Footer />
    </>
);

export default HomepageLayout;