// assets
import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined';
import QrCodeIcon from '@mui/icons-material/QrCode';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import i18react from '../i18react'

// constant
const icons = { TopicOutlinedIcon, QrCodeIcon, ArticleOutlinedIcon };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'Others',
    title: 'others',
    type: 'group',
    children: [
        {
            id: 'feedback',
            title: 'feedback',
            type: 'collapse',
            icon: icons.TopicOutlinedIcon,
            children: [
                {
                    id: 'feedbackRecords',
                    title: 'records',
                    type: 'item',
                    url: '/feedbackrecords',
                    icon: icons.ArticleOutlinedIcon
                },
                {
                    id: 'feedbackQr',
                    title: 'qr_generator',
                    type: 'item',
                    url: '/qrgen',
                    icon: icons.QrCodeIcon,
                }
            ]

            // breadcrumbs: false
        }
        // {
        //     id: 'sample-page',
        //     title: 'Sample Page',
        //     type: 'item',
        //     url: '/sample-page',
        //     icon: icons.IconBrandChrome,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'documentation',
        //     title: 'Documentation',
        //     type: 'item',
        //     url: '',
        //     icon: icons.IconHelp,
        //     external: true,
        //     target: true
        // }
    ]
};

export default other;
