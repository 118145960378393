// assets
import { IconSettings, IconHomeStats, IconChartInfographic, IconDatabaseExport, IconFileDigit } from '@tabler/icons';
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded';
import SsidChartOutlinedIcon from '@mui/icons-material/SsidChartOutlined';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import QueueOutlinedIcon from '@mui/icons-material/QueueOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';

import i18react from '../i18react'

// constant
const icons = {
    IconSettings,
    IconHomeStats,
    IconChartInfographic,
    IconDatabaseExport,
    IconFileDigit,
    InsertChartOutlinedRoundedIcon,
    SsidChartOutlinedIcon,
    AddchartOutlinedIcon,
    QueueOutlinedIcon,
    MonetizationOnOutlinedIcon
};

// ==============================|| EXTRA organization MENU ITEMS ||============================== //

const organization = {
    id: 'devices',
    title: 'Instrument',
    // caption: 'organization Caption',
    type: 'group',
    children: [
        {
            id: 'device',
            title: 'device_info',
            type: 'item',
            url: '/instrument/devices',
            icon: icons.InsertChartOutlinedRoundedIcon
        },
        {
            id: 'measurements',
            title: 'measurements',
            type: 'item',
            url: '/instrument/measurements',
            icon: icons.SsidChartOutlinedIcon
        },
        // {
        //     id: 'device-status',
        //     title: 'Device Status',
        //     type: 'item',
        //     url: '/devices/device-status',
        //     icon: icons.IconHomeStats
        // },
        {
            id: 'methods',
            title: 'methods',
            type: 'item',
            url: '/instrument/methods',
            icon: icons.QueueOutlinedIcon
        },
        {
            id: 'calibrations',
            title: 'calibration',
            type: 'item',
            url: '/instrument/calibrations',
            icon: icons.AddchartOutlinedIcon
        },
        {
            id: 'device_settings',
            title: 'sub_status',
            type: 'item',
            url: '/instrument/subscription-status',
            icon: icons.MonetizationOnOutlinedIcon,
            // children: [
            //     {
            //         id: 'subscription-status',
            //         title: 'Subscription Status',
            //         type: 'item',
            //         url: '/devices/device_settings/subscription-status'
            //         // target: true
            //     }
            // ]
        }
    ]
};

export default organization;
