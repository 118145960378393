// assets
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import AppSettingsAltOutlinedIcon from '@mui/icons-material/AppSettingsAltOutlined';
import SsidChartOutlinedIcon from '@mui/icons-material/SsidChartOutlined';
import OilBarrelOutlinedIcon from '@mui/icons-material/OilBarrelOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined';

// constant
const icons = {
    DomainAddIcon,
    CreateNewFolderOutlinedIcon,
    PeopleAltOutlinedIcon,
    CoPresentOutlinedIcon,
    MonetizationOnOutlinedIcon,
    AppSettingsAltOutlinedIcon,
    SsidChartOutlinedIcon,
    OilBarrelOutlinedIcon,
    InfoOutlinedIcon,
    DevicesOutlinedIcon,
    AddchartOutlinedIcon,
    TopicOutlinedIcon
};

// ==============================|| Admin MENU ITEMS ||============================== //

const organization = {
    id: 'admin',
    title: 'ZEUTEC Admin',
    // caption: 'organization Caption',
    type: 'group',
    children: [
        {
            id: 'all_users',
            title: 'users',
            type: 'item',
            url: '/users',
            icon: icons.PeopleAltOutlinedIcon
        },
        {
            id: 'device',
            title: 'devices',
            type: 'collapse',
            // url: '/device-info',
            icon: icons.AppSettingsAltOutlinedIcon,
            children: [
                {
                    id: 'instrument_info',
                    title: 'ins_info',
                    type: 'item',
                    url: '/device-info',
                    icon: icons.InfoOutlinedIcon
                },
                {
                    id: 'feedbackQr',
                    title: 'org_devices',
                    type: 'item',
                    url: '/organization-devices',
                    icon: icons.DevicesOutlinedIcon
                }
            ]
        },
        {
            id: 'all_measurements',
            title: 'measurements',
            type: 'item',
            url: '/all-measurements',
            icon: icons.SsidChartOutlinedIcon
        },
        {
            id: 'all_organizations',
            title: 'organizations',
            type: 'item',
            url: '/all-organizations',
            icon: icons.DomainAddIcon
        },
        // {
        //     id: 'commodity',
        //     title: 'Commodities',
        //     type: 'item',
        //     url: '/commodities',
        //     icon: icons.OilBarrelOutlinedIcon
        // },
        {
            id: 'commodity',
            title: 'commodities',
            type: 'collapse',
            // url: '/commodities',
            icon: icons.OilBarrelOutlinedIcon,
            children: [
                {
                    id: 'method',
                    title: 'methods',
                    type: 'item',
                    url: '/method',
                    icon: icons.TopicOutlinedIcon
                },
                {
                    id: 'calibrationsAdmin',
                    title: 'calibration',
                    type: 'item',
                    url: '/calibrations-admin',
                    icon: icons.AddchartOutlinedIcon
                },
                {
                    id: 'commodity',
                    title: 'commidity',
                    type: 'item',
                    url: '/commodities',
                    icon: icons.OilBarrelOutlinedIcon
                },
            ]
        },
        {
            id: 'all_subscriptions',
            title: 'subscription',
            type: 'item',
            url: '/subscriptions-admin',
            icon: icons.MonetizationOnOutlinedIcon
        },
        {
            id: 'software_package',
            title: 'software_package',
            type: 'item',
            url: '/software-package',
            icon: icons.CreateNewFolderOutlinedIcon
        },
        {
            id: 'distributor',
            title: 'distributor',
            type: 'item',
            url: '/distributors',
            icon: icons.CoPresentOutlinedIcon
        }
    ]
};

export default organization;
