import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// login routing

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const WeatherForecast = Loadable(lazy(() => import('views/dashboard/Default/weatherForecast')));

// Acc Settings Routing
const AccSetttings = Loadable(lazy(() => import('layout/MainLayout/Header/ProfileSection/accSettings.js')))

// utilities routing
// const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
// const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// Organization routing
const AllDevives = Loadable(lazy(() => import('../views/pages/organization/devicesOrg')));
const AllMembers = Loadable(lazy(() => import('../views/pages/organization/MembersAll')));
const Subscriptions = Loadable(lazy(() => import('../views/pages/organization/subscriptions')));
const Settings = Loadable(lazy(() => import('../views/pages/organization/settings')));
const Organization_View = Loadable(lazy(() => import('../views/pages/organization/organization-view')));
const GeoFencing = Loadable(lazy(() => import('../views/pages/organization/geoFencing')));
const CommodityManager = Loadable(lazy(() => import('../views/pages/organization/commodity')));
const MethodManager = Loadable(lazy(() => import('../views/pages/organization/method')));
const CalibrationsManager = Loadable(lazy(() => import('../views/pages/organization/calibration')));

// Device routing
const Measurements = Loadable(lazy(() => import('../views/pages/instrument/measurements')));
const DeviceStatus = Loadable(lazy(() => import('../views/pages/instrument/deviceInfo')));
const Methods = Loadable(lazy(() => import('../views/pages/instrument/methods')));
const Calibrations = Loadable(lazy(() => import('../views/pages/instrument/calibrations')));
const SubscriptionStatus = Loadable(lazy(() => import('../views/pages/instrument/subscriptionStatus')));

// Zeutec Admin routing
const Users = Loadable(lazy(() => import('../views/pages/zeutecAdmin/users')));
const Device_Info = Loadable(lazy(() => import('../views/pages/zeutecAdmin/device-info')));
const OrganizationDevice = Loadable(lazy(() => import('../views/pages/zeutecAdmin/orgDevicesAdmin')));
const OrganizationsAdmin = Loadable(lazy(() => import('../views/pages/zeutecAdmin/organizations')));
const SubscriptionsAdmin = Loadable(lazy(() => import('../views/pages/zeutecAdmin/subscriptions')));
const SoftwarePackage = Loadable(lazy(() => import('../views/pages/zeutecAdmin/softwarePackage')));
const Distributor = Loadable(lazy(() => import('../views/pages/zeutecAdmin/distributor')));
const FeedbackRecords = Loadable(lazy(() => import('../views/pages/others/feedbackRecords')));
const QRGen = Loadable(lazy(() => import('../views/pages/others/qrGenerator')));
const MeasurementsAdmin = Loadable(lazy(() => import('../views/pages/zeutecAdmin/measurement')));
const Commodities = Loadable(lazy(() => import('../views/pages/zeutecAdmin/commodities')));
const Method = Loadable(lazy(() => import('../views/pages/zeutecAdmin/method')));
const AdminCalibrations = Loadable(lazy(() => import('../views/pages/zeutecAdmin/calibrations')));

// sample page routing
const NotFound = Loadable(lazy(() => import('views/pages/others/pageNotFound')));
// import NotFound from '../views/pages/others/pageNotFound'

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'weather-forecast',
            element: <WeatherForecast />
        },

        { // User Acc Settings Routing
            path: 'account-settings',
            element: <AccSetttings />
        },

        { // Manager Organization Group Routing
            path: '/',
            children: [
                {
                    path: 'organizations',
                    element: <Organization_View />
                },
                {
                    path: 'all-devices',
                    element: <AllDevives />
                },
                {
                    path: 'all-members',
                    element: <AllMembers />
                },
                {
                    path: 'method-manager',
                    element: <MethodManager />
                },
                {
                    path: 'calibrations-manager',
                    element: <CalibrationsManager />
                },
                {
                    path: 'commodities-manager',
                    element: <CommodityManager />
                },
                {
                    path: 'subscriptions',
                    element: <Subscriptions />
                },
                {
                    path: 'geoFencing',
                    element: <GeoFencing />
                },
                {
                    path: 'settings',
                    element: <Settings />
                },
            ]
        },

        { // Operator & Manager Instrument Routing 
            path: 'instrument',
            children: [
                {
                    path: 'measurements',
                    element: <Measurements />
                },
                {
                    path: 'devices',
                    element: <DeviceStatus />
                },
                {
                    path: 'methods',
                    element: <Methods />
                },
                {
                    path: 'calibrations',
                    element: <Calibrations />
                },
                {
                    path: 'subscription-status',
                    element: <SubscriptionStatus />
                },
            ]
        },

        { // Admin Group Routing
            path: '/',
            children:
                [
                    {
                        path: 'users',
                        element: <Users />
                    },
                    {
                        path: 'device-info',
                        element: <Device_Info />
                    },
                    {
                        path: 'organization-devices',
                        element: <OrganizationDevice />
                    },
                    {
                        path: 'all-measurements',
                        element: <MeasurementsAdmin />
                    },
                    {
                        path: 'all-organizations',
                        element: <OrganizationsAdmin />
                    },
                    {
                        path: 'commodities',
                        element: <Commodities />
                    },
                    {
                        path: 'calibrations-admin',
                        element: <AdminCalibrations />
                    },
                    {
                        path: 'method',
                        element: <Method />
                    },
                    {
                        path: 'subscriptions-admin',
                        element: <SubscriptionsAdmin />
                    },
                    {
                        path: 'software-package',
                        element: <SoftwarePackage />
                    },
                    {
                        path: 'distributors',
                        element: <Distributor />
                    }
                ]
        },

        { // Feedback records and QR Gen routing
            path: '/',
            children: [
                {
                    path: 'feedbackrecords',
                    element: <FeedbackRecords />
                },
                {
                    path: 'qrgen',
                    element: <QRGen />
                }
            ]
        },

        // Page not found routing
        {
            path: '*',
            element: <NotFound />,
        }
    ]
};

export default MainRoutes;
