// material-ui
import { useTheme } from '@mui/material/styles';
import logo from '../assets/images/zeutec-logo.svg';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         */
        <img src={logo} alt="zeutec" width="180" height="50" />
    );
};

export default Logo;
