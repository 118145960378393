import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import HomepageLayout from 'layout/HomepageLayout';

// Homepage routing
const Homepage = Loadable(lazy(() => import('../views/homepage')));
const Pricing = Loadable(lazy(() => import('../views/homepage/Header-links/pricing')))

// ==============================|| HomePage ROUTING ||============================== //

const HomepageRoutes = {
    path: '/',
    element: <HomepageLayout />,
    children: [
        {
            path: '/',
            element: <Homepage />
        },
        {
            path: '/pricing',
            element: <Pricing />
        }
    ]
};

export default HomepageRoutes;
